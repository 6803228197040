import React from "react";
import PageTemplate from "./base";
import imgHero from "../../assets/image/industries/bar-restaurant.jpeg";

const Page = () => {
  return (
    <>
      <PageTemplate
        seoTitle="Free Staff Scheduling Software for Restaurants, Bars & Coffee Shops | Camelo"
        metaDescription="Ensure coverage for shifts, eliminate paperwork, and automatically track staff hours for restaurants, bars, and coffee shops right in the Camelo app"
        header="A scheduling app built for restaurants, bars, and coffee shops."
        subHeader="Camelo is a better way to schedule, connect, and manage your team."
        imgUrl={imgHero}
      />
    </>
  );
};

export default Page;
